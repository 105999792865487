import React, { FC } from 'react';
import Flag  from '../Flag/Flag';
import styles from './Lineup.module.scss';
import { text } from '../../data';
import { getCurrent, getCurrentArtists, getCurrentDjs, headlineSort } from '../../utils';

const Lineup:FC = () => {
  const artists = getCurrentArtists().sort(headlineSort);
  const djs = getCurrentDjs();
  const isLineupComplete = getCurrent('lineupComplete');
  const { tbaText, artistsWillBeReleased } = text;

  return (
    <>
      <div className={styles.lineup}>
        {artists.map(artist => {
          const { headliner, name, published, countryCode } = artist;
          const isHeadliner = headliner;
          const headlinerClass = isHeadliner
            ? styles.headliner
            : styles.nonHeadliner;
          const artistName = name;
          const key = `lineup artist: ${artistName}`;

          return (
            published && (
              <div key={key} className={headlinerClass}>
                {artistName}
                <Flag
                  className={
                    isHeadliner ? styles.headlinerFlag : styles.nonHeadlinerFlag
                  }
                  countryCode={countryCode}
                />
              </div>
            )
          );
        })}
      </div>
      <div className={styles.djs}>
        {djs.map(dj => {
          const { name, published, countryCode } = dj;
          const key = `lineup dj: ${name}`;

          return (
            published && (
              <div key={key} className={styles.dj}>
                {name}
                <Flag className={styles.djFlag} countryCode={countryCode} />
              </div>
            )
          );
        })}
      </div>
      {!artists.length && !!artistsWillBeReleased && <div className={styles.artistsWillBeReleasedText}>{artistsWillBeReleased}</div>}
      {!isLineupComplete && !!artists.length && <div className={styles.tbaText}>{tbaText}</div>}
    </>
  );
};

export default Lineup;
