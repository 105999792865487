export default {
  welcomeText: '',
  artistsWillBeReleased: 'Artists will soon be announced',
  tbaText: '+ more artists to be announced',
  // wrap ticket link with triple hashes, i.e. ###click here!###
  ticketText: 'Tickets available now - ###click here to get yours!###',
  ticketTextMobile: '###Get your tickets here!###',
  ticketsNotReleasedText: 'Tickets will be released shortly',
  thankYouText: "Thank you for a great evening at this year's Bodyfest! See you again in 2020!",
  soldOutText: "Tickets for this year's Bodyfest are now sold out!",
};
