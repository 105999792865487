import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './SoundCloudWidget.module.scss';

class SoundCloudWidget extends PureComponent {
  soundCloudWidgetRef = React.createRef();

  componentDidMount() {
    setTimeout(() => {
      this.soundCloudWidgetRef.current.className = `${styles.widget} ${styles.slideIn}`;
    }, 500);
  }

  render() {
    const { soundCloudId, closeWidget } = this.props;

    return (
      <div ref={this.soundCloudWidgetRef} className={styles.widget}>
        <button className={styles.closeButton} onClick={closeWidget}>
          close SoundCloud player
        </button>
        <iframe
          allow="autoplay"
          height="80"
          src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${soundCloudId}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
          title="SoundCloud Player"
          width="300"
        />
      </div>
    );
  }
}

SoundCloudWidget.propTypes = {
  closeWidget: PropTypes.func.isRequired,
  soundCloudId: PropTypes.string.isRequired,
};

export default SoundCloudWidget;
