import React, { FC } from 'react';

const Info: FC = () => (
  <section>
    <h2>Information</h2>
    <p>Bodyfest celebrates 15 years by presenting a new venue. After 13 fantastic years at Nalen we decided to move on to the coolest venue currently in Stockholm. "Kollektivet Livet" located next to Slussen's bus terminal is the former Birka terminal where the cruise ships to Mariehamn, Åland departed. Most of the original facilities are left untouched and the look and feel of the venue is quite striking, industrial if you may. In other words perfect for a festival dealing in EBM. You are hereby invited to join us to celebrate 15 years of Bodyfest on the 12th of October.</p>
  </section>
);

export default Info;
