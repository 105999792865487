import React from 'react';
import logo from '../../images/logo-no-date.jpg';
import { settings } from '../../data';
import { getCurrent } from '../../utils';
import Countdown from './Countdown';
import styles from './Header.module.scss';

const Header = () => {
  const isSoldOut = getCurrent('soldOut');
  const monthAndDayString = getCurrent('monthAndDayString');
  const yearString = getCurrent('yearString');
  const venue = getCurrent('venue');

  return (
    <>
      <div className={styles.logoWrapper}>
        <img alt="Bodyfest" src={logo} />
        {isSoldOut && <h2 className={styles.soldOutSplash}>SOLD OUT!</h2>}
        {settings.showCountdown && !getCurrent('festivalOver') && <Countdown />}
      </div>
      <div className={styles.headerDate}>
        {monthAndDayString}, {yearString} @ {venue}, Stockholm, Sweden
      </div>
    </>
  );
};

export default Header;
