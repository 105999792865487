import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.colorOverlay} />
    <div className={styles.copyrightContainer}>
      &copy; Bodyfest {new Date().getFullYear()}
    </div>
  </footer>
);

export default Footer;
