import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

const Modal = ({ body, artistId, closeModal }) => {
  const handleBackdropClick = event => {
    event.target.id === 'modalBackdrop' && closeModal();
  };

  return (
    <div
      key={`${artistId}Modal`}
      className={styles.modal}
      id="modalBackdrop"
      onClick={handleBackdropClick}
    >
      {body}
      <button className={styles.closeButton}>
        <span className={styles.closeButtonText} onClick={closeModal}>
          Close
        </span>
      </button>
    </div>
  );
};

Modal.propTypes = {
  artistId: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default Modal;
