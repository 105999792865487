import React, { FC } from 'react';
import Flag from '../Flag/Flag';
import styles from './ArtistModal.module.scss';
import SpotifyPlayer from '../Spotify/SpotifyPlayer/SpotifyPlayer';
import SoundCloudPlayer from '../SoundCloud/SoundCloudPlayer/SoundCloudPlayer';

interface ArtistModalProps {
  name: string,
  description: string[],
  countryCode: string,
  image: string,
  spotify: string,
  soundCloud: string,
  subtitle: string,
}

const ArtistModal:FC<ArtistModalProps> = ({ name, description, countryCode, image, spotify, soundCloud, subtitle }) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <img alt={name} className={styles.image} src={image} />
      </div>
      <div className={styles.artistHeader}>
        <div className={styles.name}>
          {name}
          <Flag countryCode={countryCode} />
        </div>
      </div>
      <div className={styles.description}>
        <h2 className={styles.subtitle}>{subtitle}</h2>
        {description &&
          description.map((paragraph, index) => {
            return <p key={`${name} paragraph ${index}`}>{paragraph}</p>;
          })}
      </div>
      <div className={styles.break} />
      {spotify && <SpotifyPlayer spotifyId={spotify} />}
      {soundCloud && <SoundCloudPlayer soundCloudId={soundCloud} />}
    </div>
  );
};

export default ArtistModal;
