export default [
  {
    id: 'klubbDod',
    name: 'Klubb Död',
    countryCode: 'se',
    published: true,
    year: '2019',
  },
  {
    id: 'alexTorje',
    name: 'Alex Torje',
    countryCode: 'no',
    published: true,
    year: '2019',
  },
  {
    id: 'subkult',
    name: 'Subkultfestivalen',
    countryCode: 'se',
    published: true,
    year: '2019',
  },
  {
    id: 'peterMastbooms',
    name: 'Peter Mastbooms',
    countryCode: 'be',
    published: true,
    year: '2019',
  },
];
