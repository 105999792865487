import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './SpotifyWidget.module.scss';

class SpotifyWidget extends PureComponent {
  spotifyWidgetRef = React.createRef();

  componentDidMount() {
    setTimeout(() => {
      this.spotifyWidgetRef.current.className = `${styles.widget} ${styles.slideIn}`;
    }, 500);
  }
  render() {
    const { spotifyId, closeWidget } = this.props;

    return (
      <div ref={this.spotifyWidgetRef} className={styles.widget}>
        <button className={styles.closeButton} onClick={closeWidget}>
          close Spotify player
        </button>
        <iframe
          frameBorder="0"
          height="80"
          src={`https://embed.spotify.com/?uri=spotify:artist:${spotifyId}`}
          title="Spotify Player"
          width="300"
        />
      </div>
    );
  }
}

SpotifyWidget.propTypes = {
  spotifyId: PropTypes.string.isRequired,
  closeWidget: PropTypes.func.isRequired,
};

export default SpotifyWidget;
