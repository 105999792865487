import React, { PureComponent } from 'react';
import dayjs from 'dayjs';
import { getCurrent } from '../../utils';
import styles from './Countdown.module.scss';

class Countdown extends PureComponent {
  state = {
    countdown: {
      months: '00',
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    },
  };

  setCountdown() {
    const isoString = `${getCurrent('dateString')}T${getCurrent(
      'timeString'
    )}:00`;
    dayjs.locale('sv');
    const startTime = dayjs(isoString);
    const currentTime = dayjs();
    const monthsLeft = startTime.get('M') - currentTime.get('M');
    let daysLeft = startTime.get('date') - currentTime.get('date');
    let hoursLeft = startTime.get('h') - currentTime.get('h');
    let secondsLeft = startTime.get('s') - currentTime.get('s');

    if (startTime.get('h') < currentTime.get('h')) {
      hoursLeft += 24;
    }
    let minutesLeft = startTime.get('m') - currentTime.get('m');

    if (startTime.get('m') < currentTime.get('m')) {
      minutesLeft += 60;
    }

    if (startTime.get('s') < currentTime.get('s')) {
      secondsLeft += 60;
    }

    this.setState({
      months: String(monthsLeft).length > 1 ? monthsLeft : `0${monthsLeft}`,
      days: String(daysLeft).length > 1 ? daysLeft : `0${daysLeft}`,
      hours: String(hoursLeft).length > 1 ? hoursLeft : `0${hoursLeft}`,
      minutes: String(minutesLeft).length > 1 ? minutesLeft : `0${minutesLeft}`,
      seconds: String(secondsLeft).length > 1 ? secondsLeft : `0${secondsLeft}`,
    });
  }

  componentDidMount() {
    setInterval(() => this.setCountdown(), 1000);
  }

  render() {
    const { months, days, hours, minutes, seconds } = this.state;
    return (
      <div className={styles.countdownWrapper}>
        <span className={styles.countdown}>
          <span className={styles.box}>{months}</span>:
          <span className={styles.box}>{days}</span>:
          <span className={styles.box}>{hours}</span>:
          <span className={styles.box}>{minutes}</span>:
          <span className={styles.box}>{seconds}</span>
        </span>
      </div>
    );
  }
}

export default Countdown;
