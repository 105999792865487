import React from 'react';

const Contact = () => (
  <section style={{ textAlign: 'center' }}>
    <h1>Contact</h1>
    <p>Please send all inquiries to info (at) bodyfest (dot) se</p>
  </section>
);

export default Contact;
