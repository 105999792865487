import * as images from './images';

export default [
  {
    id: 'theYoungGods',
    name: 'The Young Gods',
    image: images.theYoungGods,
    headliner: true,
    description: [
      `A big warm welcome to these Swiss industrial giants that haven't played
        in Sweden since the Arvika festival in 2006.`,
      `Formed in 1985 with a band name adapted from an early Swans song title
        and over 30 years later they have released 11 studio albums, several
        live albums, composed soundtracks to various movies and worked together
        with string orchestras. In Switzerland and especially in their home town
        Geneva they are considered a cultural treasure and are frequently
        consulted when it comes to composing music for various installations and
        other happenings.`,
      'The Young Gods have been pushing the limits of sound ever since they started. They began as pioneers of industrial punk but the genres later on even include surreal cabaret, techno and ambient music. If you think that Ministry invented the genre "industrial punk" you have to think again and listen to the track "Envoye" by The Young Gods that was released in 1986. At the time both band were still affiliated with the same record company, "Wax Trax", so with that knowlegde in mind we just have to do the math.',
      'When David Bowie released "Outside" in 1995 he was asked if he was influenced by Nine Inch Nails and Bowie answered. "The band that I was actually quite taken with was three guys from Switzerland called The Young Gods..."',
      'The Young Gods first got introduced to the Swedish audience in 1992 when they played in the Sahara tent at the Hultsfred festival. Almost everybody who attended that gig found salvation. With the newly released album "T.V. Sky" they made such an intense performance, leaving the spectators with open mouths drooling.',
      'Now it\'s time for a new generation to experience this intense live act and at Bodyfest we expect endless hitsongs coming our way. With a catalogue of songs like Strangel, T.V.Sky, Skinflowers, Envoye, Lucidogen, Our House, Kissing the Sun, Longue Route, Gasoline Man and the cover song "Did you miss me" it\'s hard not to look forward to one of the greatest industrial bands out there.',
    ],
    countryCode: 'ch',
    spotify: '1xF9B1eiCKUejBnEE9k3sY',
    published: true,
    year: '2018',
  },
  {
    id: 'spock',
    name: 'S.P.O.C.K',
    image: images.spock,
    headliner: true,
    description: [
      'Klingons, aliens, androids and audience of Bodyfest – this is an important message from outer space. The number one boy band of the universe is a part of the Bodyfest 2018 line-up and celebrates 30 years as a band. Don´t miss out on being a part of this never ending party!',
      'Star Pilot On Channel K – perhaps more known as S.P.O.C.K – are, with their spacepop, completely unique in the scene. The band was founded by Android and Cpt. Eddie B. Kirk, based on planet Sweden, in 1988. Commercial success started in 1990 with the release of “Silicon Dream”, nowadays an ultra-rare collector’s item. But this was just the beginning of what would become an initiatively witty career. Shortly after they appeared on “A Swedish Tribute to Depeche Mode”, made numerous concerts that took the scene with storm and they soon became a cult phenomenon, not only in Sweden but also abroad, which eventually landed a record deal. The first to come out of this new collaboration was the now legendary single “Never Trust a Klingon”.',
      'Up until 2001 the band released a long series of catchy songs on five studio albums and got several SAMA (Scandinavian Alternative Music Awards) awards including “Best Artist”, “Best Live Act” and “Best Album”. Their live shows are well known to be amazingly enthusing. S.P.O.C.K lives up to its principle of being not just musicians but entertainers in its true meaning. Fortunately, original founder Android and his supporting crew, have after 30 years no desire whatsoever to do a farewell tour – it’s just too much fun in going on and on and on!',
      'The Bodyfest crew is proud to welcome the star pilots to the big stage of Nalen. Spock’n’roll!',
    ],
    countryCode: 'se',
    spotify: '2sDfbgbPqK8cnEDV5bCyby',
    published: true,
    year: '2018',
  },
  {
    id: 'leaetherStrip',
    name: 'Leaether Strip',
    image: images.leatherStrip,
    headliner: false,
    description: [
      'Bodyfest proudly present one more of the great living EBM-legends of the world, Leæther Strip!',
      'Leæther Strip was founded in Denmark back in 1988 by Claus Larsen and was one of the earliest and most important bands on the legendary German record label Zoth Ommog. Since then they have released at least eighteen albums and have given all friends of heavy Electronic Music hits like "Strap me down", "Evil speaks" and "Torment Me".',
      'Leæther Strip is a never ending EBM-machine and we can promise a really intensive and unforgettable show live on stage!',
      'With thirty years in the EBM-scene, Leæther Strip is one of the most important bands we have presented. With hard and at the same time extremely danceable rhythms, with heavy drums and even a great portion of humour, we are extremely proud to welcome Claus Larsen and Leæther Strip to Sweden and Bodyfest. Strap us all down!',
    ],
    countryCode: 'dk',
    spotify: '0klcoRwPQF1GMv8FrA7F8V',
    published: true,
    year: '2018',
  },
  {
    id: 'underviewer',
    name: 'Underviewer',
    image: images.underviewer,
    headliner: false,
    description: [
      'Finally we have this long awaited booking to look forward too. A band that few people know of but almost everybody has heard before.',
      'Before joining FRONT 242 in 1981 and devoting several decades to this band, Patrick Codenys and Jean-Luc De Meyer worked together as a duo between 1978-1981 under the name Underviewer, using a bass, analog synthesizers, rhythm boxes and a tape recorder. Dormant for over 30 years, Underviewer recovered tracks from their early 4-track tape recordings and even recreated some of them by reusing the same vintage machines for the release in December 2016 of their first full length album "Wonders & Monsters"',
      'The album consist of shy, experimental electronic tracks clearly inspired by pop, but based more on sound associations and noise rather than the catchy melodies. Reactivated after many years, the Underviewer takes listeners on a journey back in time, to the period in which there were no prefabricated sounds yet and hyperkinetic rhythms were still to be invented. The musicians decided to resurrect their first project in search of what they felt in the past, creating their early electronics sounds on old synthesizers. The brilliant and characteristic voice of Jean-Luc De Meyer combined with the music of Patrick Codenys, the core of Front 242, going back to the origin.',
      'The songs Syncussion, I Remember and Trouble are to be found as bonus tracks on the Front 242 re-release of Geography and one of the more famous Front 242 songs of all time "Kampfbereit" is actually an Underviewer song from the beginning.',
      'Bodyfest are pleased to welcome back both Jean-Luc De Meyer and Patrick Codenys but this time under a new (old) name.',
    ],
    countryCode: 'be',
    spotify: '3ewIUUEQiSJQg98w21pCfH',
    published: true,
    year: '2018',
  },
  {
    id: 'dieselDudes',
    name: 'Diesel Dudes',
    image: images.dieselDudes,
    headliner: false,
    description: [
      'The Bodyfest crew are thrilled to present these dumbell lifting strongmen from Oakland, California, as a part of the line-up this year. After shows at both Familientreffen and Kalabalik has the American band, formed in 2010, become known to the European EBM scene.',
      'Diesel Dudes songs are all about muscles, going to the gym and adrenaline delivered in a danceable, energizing, electro punky way. Their self-titled genre "UNDERPRODUCED SWEATY, HYPER ROMAN ELECTRO PUNK FOR YOU TO HEAR WHILE YOUR BODY IS GROWING STRONGER" describes quite well what to expect of the show on the small stage of Bodyfest.',
    ],
    countryCode: 'us',
    spotify: '4EKSiDDkBWgVd8k8i5dRA8',
    published: true,
    year: '2018',
  },
  {
    id: 'roya',
    name: 'Roya',
    image: images.roya,
    headliner: false,
    description: [
      'From the small but thriving scene of Stockholm we bring you our newest star Roya. She moved to Sweden in 2010 from Seattle and started making electronic music just before her release of the single "Centrum" and the debut E.P. "Trax" that was released in 2016.',
      'While the recorded stuff is melancholic with beautiful melodies here and there, the live experience is a somewhat different story. Turning and twisting on every knob in her collection of synthesizers, she summons the haunting and chaotic spirits that wants to suck your soul dry.',
      'With the newly self-released debut album "Hive" from May this year Roya takes another step in opening up for a bigger crowd and we are so pleased to welcome her to Bodyfest this year.',
      'Hit songs you should listen closely to are the singles "Centrum" and "Home", the beautiful "Day one" and the dark and fateful "Mode". How these songs will sound live you\'ll never know until you stand in front of the small stage at Nalen on the 6th October. It will be an awesome show though, that much we can tell you.',
    ],
    countryCode: 'se',
    spotify: '6Qdvq4rGJg67QoekiOiTjx',
    published: true,
    year: '2018',
  },
  {
    id: 'front242',
    name: 'Front 242',
    image: images.front242,
    headliner: true,
    description: [
      'Could there be any better way to close the circle with a decade of Bodyfest? After 9 whole years Front 242 are back to headline Scandinavia’s EBM-festival. They will return after a year in hiatus and this time we have ourselves a quite an exclusive show where Bodyfest is one of only four dates for 2019. While ”Vintage” still is the alias for the performance we of course hope to hear some old songs that hasn’t been aired for a while. Let us welcome the mighty 242 to end Bodyfest’s 10 year anniversary at the big stage.',
      '5 - 10 - 2019 - The stars are shining bright',
    ],
    countryCode: 'be',
    spotify: '2tyMOS8xKREgpEwHnLc6EX',
    published: true,
    year: '2019',
  },
  {
    id: 'die-krupps',
    name: 'Die Krupps',
    image: images.dieKrupps,
    headliner: true,
    subtitle: 'Performing a special electro set for Bodyfest',
    description: [
      'In the beginning, with the first primal steps of EBM, you could clearly hear the influences from Punk Rock. Both D.A.F and Die Krupps came from the Punk scene and what you heard on "Die Kleinen und Die Bösen" with songs like "Nacht Arbeit" and "Essen Dann Schlafen" was taken into perfection with "Volle Kraft Voraus" and especially "Tod und Teufel", both songs on Die Krupps\' second album "Volle Kraft Voraus" from 1982.',
      'Ever since the monumental release "I" in 1992, Die Krupps musical style could be described as Industrial Metal or by their own words, Metal Machine Music. With endless hits from the 90\'s era they have toured the world and had a great influence on the Neue Deutsche Härte scene with bands like Rammstein, Eisbrecher etc.',
      "Although there's still a few of us that remember that Die Krupps was there to define the EBM scene in the beginning of the 80's, it's was a long time ago we were able to witness and hear this music performed live. A light of hope was heard on the 2010 release of mini-album",
      '"Als wären wir für immer" where songs like "The Chameleon Man" and the cover of Ralf Dörper\'s own Propaganda song "Dr Mabuse" showed a more electronic approach to the songs than heard',
      'in a very long time. The hopes where even higher with the brilliant album "The Machinist of Joy" from 2015 including one of the best EBM-songs ever made, "Schmutzfabrik". However, on stage Die Krupps has stuck to the world of Metal Machine Music.',
      'Now, the Bodyfest audience will be able to witness something that in Sweden hasn\'t happened since the late 80\'s, where we get to hear Die Krupps present their old songs from "Volle Kraft Voraus" together with newer tracks in a strict electronic set up. We have been trying to get this show aboard ever since 2016 and finally our persistence paid off!',
      'Dare we say we are super thrilled? This will be a milestone in the history of Bodyfest!!',
    ],
    countryCode: 'de',
    spotify: '0iDagYU2nu7gTWMdtCxzxW',
    published: true,
    year: '2019',
  },
  {
    id: 'severe-illusion',
    name: 'Severe Illusion',
    image: images.severeIllusion,
    headliner: false,
    subtitle: 'Performing songs from the debut album "Discipline is reward enough"',
    description: [
      'It\'s been 16 years since the first real album by Severe Illusion saw first light. These gentlemen met by chance in 2000 and found chemistry that would pay off extremely really well in the years that followed. Their first release on a label, the EP "Discover without reflection", came in 2002 and it was a promising taste of what was about to come. When the album showed up the following year it hit by full force. "Discipline is reward enough" is a monster of an album and it has definitely not lost anything over the tide of time. The remastered version released by Advoxya (HUN) in 2017 contained, except for the remastered versions or the original songs, even old demos, live-versions and collaborations with other artists.',
      'The idea for a show surrounding this album came with the re-release and the sole reason for this show is that the album is too f*****g awesome to let it slip by somewhat unnoticed.',
      'With this release, the band has returned, to some extent, to their chaotic past and it is extremely pleasant to present a special show like this for the Bodyfest audience.',
    ],
    countryCode: 'se',
    spotify: '1IGsUGUr24rd2VLuMlX1Fl',
    published: true,
    year: '2019',
  },
  {
    id: 'presto-fervant',
    name: 'Presto Fervant',
    image: images.prestoFervant,
    headliner: false,
    description: [
      'The old school EBM band Presto Fervant formed in 1989 was the first band of Fredrik Lundvall Kindsäter (Octolab) and Ronny Larsson (Container 90). The duo released a split-CD together with the band Punch & Judy 1992 and made a performance at Hultsfredsfestivalen the same year. Presto Fervant officially quit in 1994, but the songs were re-released in 2006 by the German EBM organization Electric Tremor. Despite of the bands short career it made an impact of the old school EBM scene and has got a cult status over the years.',
      "When Electric Tremor turned 10 years in 2012 the band made an exclusive show and of course Presto Fervant is doing the same thing at Bodyfest. Don't miss this special opportunity!",
    ],
    countryCode: 'se',
    spotify: null,
    soundCloud: '118141069',
    published: true,
    year: '2019',
  },
  {
    id: 'elm',
    name: 'Elm',
    image: images.elm,
    headliner: false,
    description: [
      'ELM is the latest project of Peter Elm, known from the band Restricted Area. Peter Elm has been in the scene for a long time and really know the art of making minimalistic EBM. The basslines, powerful vocals and attitude of ELM hooked enthusiasts of old school EBM from the start and we will surely hear more from ELM in the future.',
      'We are proud to present the first ELM show in Sweden at Bodyfest. Make sure to lace your boots!',
    ],
    countryCode: 'se',
    spotify: '0wWzCcMztzTN7by91PYiVB',
    published: true,
    year: '2019',
  },
  {
    id: 'nattskiftet',
    name: 'Nattskiftet',
    image: images.nattskiftet,
    headliner: false,
    description: [
      'Nattskiftet was founded in Gothenburg in 2015 and quick became known within the Swedish EBM scene. Like many EBM-bands the songs of Nattskiftet are romanticizing hard factory work, but the EBM bass lines mixed with pop and the female vocals makes their catchy music stand out.',
      "Is there a better way to start the evening of the 10th Bodyfest than Nattskiftets first show in Stockholm? We don't think so!",
    ],
    countryCode: 'se',
    spotify: '0MPLC62Bqifz9aX6mTIlDw',
    published: true,
    year: '2019',
  },
  {
    id: 'absoluteBodyControl',
    name: 'Absolute Body Control',
    image: images.absoluteBodyControl,
    headliner: true,
    description: [
      `Formed in 1980 they immediately achieved an underground cult following
      where they took their influence from bands such as Suicide, D.A.F,
      and the UK electronic scene.
      After releasing a 7 inch, several tapes and many compilation contributions
      worldwide, the band members went separate ways, with DIRK IVENS and
      ERIC VAN WONTERGEHEM finding success in other influential acts such as
      The Klinik, Dive Blok 57, S/HE, Motor!k, Sonar, Monolith, and Insekt.`,
      `22 years after splitting up Absolute Body Control re-formed again and
      released “Lost & Found”, “Tapes 81-89” and "Wind[Re]Wind", which contains
      newly recorded versions of their best tracks. 
     Following these releases, Absolute Body Control returned in 2010 with
     the albums "Shattered Illusion" and "Mindless Intrusion", a whole new set
     of classic tracks, featuring modern sound recording techniques that blend
     seamlessly with the roots of low-fi electro, an absolute nod to the past. 
     "Forbidden Games", a selection of compilation tracks released in 2016,
     beautifully points to the early roots of electronic pop and industrial and
     the band members have been influencing and inventing genres ever since. 
     `,
      `Absolute Body Control will celebrate their 40th anniversary with some
      rare exclusive live dates, among them of course Bodyfest who will host
      these giants for the first time.`,
    ],
    countryCode: 'be',
    spotify: '0sY0sggayqH5fxwe3abfUn',
    published: true,
    subtitle: '40 year anniversary',
    year: '2020',
  },
  {
    id: 'spitMask',
    name: 'Spit Mask',
    image: images.spitMask,
    headliner: false,
    description: [
      `Spit Mask is an industrial project residing in Berlin,
     Germany consisting of Bryan and Rachel Jackson, both on vocals and
     electronics. The synth driven pair mix early industrial with modern
     machines to create a torrent of hate, love, and sex. Their debut
     full length LP titled You May Feel Some Pressure was released
     September of 2019 through Aufnahme + Wiedergabe and has ended up on
     many best of lists around the world. Don’t forget to check out the
     mini album “Swallow” from 2016 which also is a fantastic experience.
     Spit Mask never played live in Sweden and if this will be their 
     Swedish debut is hard to say right now but it will definetely be their 
     Stockholm debut. `,
    ],
    countryCode: 'us',
    spotify: '4v3kPOCKxh3f2nUhE20epK',
    published: true,
    year: '2020',
  },
  {
    id: 'absoluteBodyControl',
    name: 'Absolute Body Control',
    image: images.absoluteBodyControl,
    headliner: true,
    description: [
      `Celebrating over 40 years as a band, Dirk Ivens and Eric Van Wonterghem will 
      be coming to Stockholm to play hitsongs spanning from their cult hit "Is there an Exit?"
      (1981) to their newly released album "A New Dawn" (2021).`,
    ],
    countryCode: 'be',
    spotify: '0sY0sggayqH5fxwe3abfUn',
    published: true,
    year: '2021',
  },
  {
    id: 'agentSideGrinder',
    name: 'Agent Side Grinder',
    image: images.agentSideGrinder,
    headliner: true,
    description: [
      `Definitely one of the biggest Swedish bands in alternative music. Their current album
      "A/X" was one of the best albums, all categories, in 2019 and they are of course eager
      to perform these songs once again before heading in to the studio and work on the sequel.`,
    ],
    countryCode: 'se',
    spotify: '0kMA8StaJw3CUvN42xCLSe',
    published: true,
    year: '2021',
  },
  {
    id: 'theOperatingTracks',
    name: 'The Operating Tracks',
    image: images.theOperatingTracks,
    headliner: false,
    description: [
      `On the line-up again after their stunning performance in 2015. Back with new material 
      that will be presented live together with some amazing guests. `,
    ],
    countryCode: 'se',
    spotify: '5vWpbGApu1XDwojqIxa38j',
    published: true,
    year: '2021',
  },
  {
    id: 'annaOberg',
    name: 'Anna Öberg & Härsk',
    image: images.annaOberg,
    headliner: false,
    description: [
      'Anna Öberg with a past in synth/new wave band Ladomir released her first solo album in 2017 and her latest and third arrived last year. Musically hard to describe but try analogue synthpop with layers of punk and industrial and with ingenious lyrics in Swedish. Last performance in Stockholm with Klubb Död in 2018 witnessed a band fit for a larger stage.',
    ],
    countryCode: 'se',
    spotify: '2jmJ6bpnQ851pYXQ9dcyCR',
    published: true,
    year: '2021',
  },
  {
    id: 'spitMask',
    name: 'Spit Mask',
    image: images.spitMask,
    headliner: false,
    description: [
      `Spit Mask is an industrial project residing in Berlin, Germany consisting of Rachel and Bryan Jackson, both on vocals and electronics. 
      The synth driven pair mix early industrial with modern machines to create a torrent of hate, love, and sex. Their debut full length LP was released
      in 2019 and we can only hope that a follow up is not far away. Expect intensity driven to eleven.`,
    ],
    countryCode: 'us',
    spotify: '4v3kPOCKxh3f2nUhE20epK',
    published: true,
    year: '2021',
  },
  {
    id: 'portionControl',
    name: 'Portion Control',
    image: images.portionControl,
    headliner: true,
    description: [
      "A warm comeback welcome to these industrial/EBM pioneers that did an amazing show at Nalen in 2015. Founded in London in 1980 they did inspire a lot of bands that now are the absolute biggest names in the scene e.g. NIN, Skinny Puppy etc. With pulsating raw and punky EBM the audience will have a hard time standing still. It's our great pleasure to once again invite Portion Control to Bodyfest where we will hear the best mix of new and old songs.",
    ],
    countryCode: 'uk',
    spotify: '6M4dtbJkJLIR2GOsHJgRnY',
    published: true,
    year: '2022',
  },
  {
    id: 'testDept',
    name: 'Test Dept',
    image: images.testDept,
    headliner: true,
    description: [
      'Another group of Industrial giants joining the lineup and it could very well be the first time in Stockholm. Formed in London in 1981 they quickly became one of the most influential industrial acts along side with Portion Control and Cabaret Voltaire to mention a few. They split up in 1997 and for long we thought that was the end of Test Dept. Then suddenly they re-emerged in 2014 and released a brilliant album in 2019, "Disturbance" that was too good to ignore. Being on our list since 2020 and put forward because of the pandemic we are thrilled to finally have them on the big stage of Bodyfest. ',
    ],
    countryCode: 'uk',
    spotify: '31obJnEmHX81bUzzfLAiM6',
    published: true,
    year: '2022',
  },
  {
    id: 'emmon',
    name: 'Emmon',
    image: images.emmon,
    headliner: false,
    description: [
      "Emma Nylén needs no longer presentation for the Swedish audience. Ever since the first solo album in 2007, “The Art and the Evil”, Emmon was instantly known in Sweden's alternative scene. With the fourth album in 2014 she took a five long year break, before the comeback as support to Nitzer Ebb in 2019. And this also with a slightly different sound. While the first four albums could be categorized as electro and synthpop, the new songs we have heard have a clear reference to EBM, harder, heavier but without losing the touch for awesome hit songs that we know from previous albums. A brand-new single is released this week and album number five “RECON” is released this fall, just in time for Bodyfest. We are super excited!",
    ],
    countryCode: 'se',
    spotify: '3WvUXfAhZSBw2ELfRGhnnP',
    published: true,
    year: '2022',
  },
  {
    id: 'celldod',
    name: 'Celldöd',
    image: images.celldod,
    headliner: false,
    description: [
      "Celldöd, aka Anders Karlsson, has been releasing dirty electronic music since the late '80s, both small-run cassette tapes inspired by the DIY ethos of his post-punk background, and pristine vinyl editions. Karlsson's music is uncompromising and edgy, with roots in both early electronic and '70s industrial music from Britain, as well as US techno and Kraut-inspired German electronics. With the first time at Bodyfest for Celldöd we expect a specially designed and raw head-banging, boot-stomping and ass-dancing set that will blow your mind!",
    ],
    countryCode: 'se',
    spotify: '61oU79623U6B1F8rv5PnCZ',
    published: true,
    year: '2022',
  },
  {
    id: 'zweiteJugend',
    name: 'Zweite Jugend',
    image: images.zweiteJugend,
    headliner: false,
    description: [
      'In 2015 the two long time friends Marcel and Eli founded the band Zweite Jugend. Their minimalistic music inspired by the EBM of the early 80s and made of analogue sequences and acoustic drums soon became popular among EBM fans. Over the last few years Zweite Jugend has performed at several festivals including WGT, NCN and BIMFEST and in October Marcel and Eli enter the small stage of Bodyfest. You surely don’t want to miss Zweite Jugends first show in Sweden. Hoch die Tassen!',
    ],
    countryCode: 'de',
    spotify: '6yKnkPObXgyf4JjRhHcW2t',
    published: true,
    year: '2022',
  },
  {
    id: 'leroySeMeurt',
    name: 'Leroy Se Meurt',
    image: images.leroySeMeurt,
    headliner: false,
    description: [
      'The French duo Leroy Se Meurt formed in 2018 is shaped by backgrounds in punk rock and electronic music. The distinctive sound of Leroy Se Meurt is abrasive and hypnotic, an energetic old school EBM fused with fierce electro and bound by punk ethos. Last summer Leroy Se Meurt performed at the Swedish festival Kalabalik på Tyrolen and we are glad to welcome them to Bodyfest 2022.',
    ],
    countryCode: 'fr',
    spotify: '2APuALnxzC2I0VYNIGGg0a',
    published: true,
    year: '2022',
  },
  {
    id: 'leatherStrip',
    name: 'Leæther Strip',
    image: images.leatherStrip,
    headliner: false,
    description: [
      'Bodyfest proudly present one of the great living EBM-legends of the world! Leæther Strip was founded in Denmark back in 1988 by Claus Larsen and was one of the earliest and most important bands on the legendary German record label Zoth Ommog. Since then they have released well over 20 albums and have given all friends of heavy Electronic Music hits like "Strap me down", "Evil speaks" and "Torment Me". Leæther Strip is a never ending EBM-machine and we can promise a really intensive and unforgettable show live on stage!',
      'With thirty years in the EBM-scene, Leæther Strip is one of the most important bands we have presented. With hard and at the same time extremely danceable rhythms, with heavy drums and even a great portion of humour, we are extremely proud to welcome Claus Larsen and Leæther Strip to Sweden and Bodyfest. Strap us all down!',
    ],
    countryCode: 'dk',
    spotify: '0klcoRwPQF1GMv8FrA7F8V',
    published: true,
    year: '2023',
    showFullDescription: true,
    links: {
      facebook: 'https://www.facebook.com/LeaetherStrip',
      bandcamp: 'https://leaetherstrip.bandcamp.com/',
    },
  },
  {
    id: 'botbr',
    name: 'Brides of the Black Room',
    image: images.botbr,
    headliner: false,
    description: [
      'The Brides of the Black Room are more like an art collective than a regular band. With the fantastic debut album "Blood & Fire" from 2021 they told their story through their weird and beautiful videos. From the prologue of "Ambulance" to the season finale in "Watch me Burn" we went for a ride together with the first seasons three main characters. Then silence. Until now. The Brides of the Black Room are starting up their second season at Bodyfest with their first official show ever. The     ever so cool front woman, Lea Alazam, are leading the troops towards new exciting adventures. Be     prepared to see a spectacular show together with amazing songs.',
    ],
    countryCode: 'se',
    spotify: '35fafKhcshJbUYbBnM6PW1',
    published: true,
    year: '2023',
    links: {
      facebook: 'https://www.facebook.com/tbotbr',
      bandcamp: 'https://thebridesoftheblackroom.bandcamp.com/',
    },
  },

  {
    id: 'c90',
    name: 'Container 90',
    image: images.c90,
    headliner: false,
    description: [
      'A decade ago, at Bodyfest 2013, Container 90 entered our small stage as the first band of the festival. The show was a slap in the face to the audience and gave them much needed energy for the rest of the festival. At Bodyfest 2023, Container 90, will be the opening band once again. Be sure not to miss out. You are in for a treat! The band, founded in 2004, is known for its heavily punk influenced EBM music which can be heard in both vocals, lyrics and musical composition. During the years they have released smashing hits like ”Oldschool 84”, ”Richard is a racist” and ”EBM radio”. Container 90 are about to release a new album this autumn and the chances are high they will play some of the new songs at the festival. We are glad to welcome Container 90 back to Bodyfest!',
    ],
    countryCode: 'se',
    links: {
      facebook: 'https://www.facebook.com/container90',
      bandcamp: 'https://container90.bandcamp.com/',
    },
    spotify: '4MHWISvr8CHu0KwaZKUCuS',
    published: true,
    year: '2023',
  },
  {
    id: 'auxAnimaux',
    name: 'aux animaux',
    image: images.auxAnimaux,
    headliner: false,
    description: [
      'aux animaux is a darkwave artist based in Stockholm, Sweden. Her songs draw inspiration from the 80s dark wave and goth bands, as well as occultism and horror movies. Born and raised in Istanbul, she is a self-taught musician that worked as a professional bass guitarist for several years up until moving to Sweden almost 15 years ago. With aux animaux, on stage she sings as well as alternating between theremin and bass guitar. The theremin, which she considers to be her source of sorcery, is the only instrument that is played without actually touching. With several releases since the start in 2015 we can also expect new songs to be heard at Bodyfest. Expect a really strong visual experience.',
    ],
    countryCode: 'se',
    spotify: '5jdlUKIbzsVj5TPXqq27SD',
    published: true,
    year: '2023',
    links: {
      facebook: 'https://www.facebook.com/auxanimaux/',
      bandcamp: 'https://auxanimaux.bandcamp.com',
    },
  },
  {
    id: 'kunt',
    name: 'KUNT',
    image: images.kunt,
    headliner: false,
    description: [
      "From the south of Sweden comes Paula Lè Boss and Rickard Rosendahl aka KUNT, a fairly new acquaintance on the Swedish alternative scene. Their blend of punk, ebm and synth-pop is quite rare and mixed with the energetic live performances we recommend that you don't miss out on their first show in Stockholm at Bodyfest. KUNT’s first album is yet to be released but the duo released a new EP in March and the songs are so strong and intriguing that we had to book them at first sight. Check it out on Bandcamp.",
    ],
    countryCode: 'se',
    spotify: null,
    links: {
      facebook: 'https://www.facebook.com/KUNT.MUSIK',
      bandcamp: 'https://kunt.bandcamp.com/',
    },
    published: true,
    year: '2023',
  },
  {
    id: 'daf',
    name: 'Deutsch Amerikanische Freundschaft',
    image: images.daf,
    headliner: true,
    description: [
      'DEUTSCH AMERIKANISCHE FREUNDSCHAFT is probably one of the best known and most influential electro-punk/Nue Deutsche welle bands and was founded already in 1978. Although DAF started as a "full band" the line-up was narrowed down to Gabi Delgado (Vocals) and Robert Görl (Drums) by 1981. Sadly frontman and singer Gabi Delgado unexpectedly died on 22 March 2020 aged 61 in the middle of recording a new upcoming album. At this point Robert Görl decided to finish the album on his own, with some help from producer Sylvie Marks, as a tribute to his passed away life-time colleague and friend.',
      "He named the album ‘Nur noch einer!’ (Only one more!) and started touring Europe soon after with these songs. Meanwhile the live-set evolved and more 'older' and 'classic' DAF songs are also being performed. Instead of trying to copy the energetic stage performances of Gabi Delgado, Robert is taking a completely different approach. No longer behind the drums, but instead behind the mic and backed with Sylvie Marks on synths & electronics, he presents us the story and legacy of DAF in his own respectful way.",
    ],
    countryCode: 'de',
    spotify: '4KtyUYo9zaM9YggIVc7uxx',
    published: true,
    year: '2023',
  },
  {
    id: 'dive',
    name: 'DIVE',
    image: images.dive,
    headliner: true,
    description: [
      'A living legend returns to Bodyfest after the fantastic show in 2016. Dirk Ivens started out with Absolute Body Control in 1979 and continued with The Klinik who became one of the biggest bands of them all within the EBM-scene. In the beginning of the 90\'s he started releasing music on his own as Dive and the latest release "Where do we go from here?" was released in 2020. Seeing Dive perform live you will get an experience that affects all senses. You would never believe the energy you will get out of a one man show. Welcome back to Bodyfest Dirk Ivens.',
    ],
    countryCode: 'be',
    spotify: '4yMiDG6xmnKwBWwxc7JTYj',
    published: true,
    year: '2024',
    links: {
      facebook: 'https://www.facebook.com/TheKlinikDiveSonarAbsoluteBodyControl',
      bandcamp: 'https://divebelgium.bandcamp.com',
    },
  },
  {
    id: 'lucifersAid',
    name: "Lucifer's Aid",
    image: images.lucifersAid,
    headliner: false,
    description: [
      "Carl Nilsson aka. Lucifer's Aid has quickly established himself as one of the strongest new forces on the EBM scene over the last years with shows on all major festivals such as Wave Gotik Treffen, NCN, Amphi Festival, E- tropolis to name a few. With an exceptional sense for making hit songs he has released five albums and a bunch of singles/EP's since 2016. The latest release \"Destruction\" came late last year and we dare to say that all songs on the album are superb and smashing EBM hit songs. We don't know why it took so long but 2024 is finally the year that Lucifer's Aid will debut on Bodyfest.",
    ],
    countryCode: 'se',
    spotify: '39NEVScnI4qOmXQGJ1GIqa',
    published: true,
    year: '2024',
    links: {
      facebook: 'https://www.facebook.com/lucifersaid',
      bandcamp: 'https://lucifersaid.bandcamp.com',
    },
  },
  {
    id: 'nsbtm',
    name: 'No Sleep by the Machine',
    image: images.nsbtm,
    headliner: false,
    description: [
      "With energy, joy of performing and some musical darkness No Sleep By The Machine are welcome to play on Swedish soil again for the first time in many years. Twenty years as a band since the start in 2004 and fifteen years since they performed at the very first Bodyfest they have released two albums and twelwe EP's. The uncomplicated attitude in everything they do is also their strength. Stripped down, drums, leads, an occasional string and vocals, that's about it. NSBTM will bring EBM back to the punk rock roots at Bodyfest 2024.",
    ],
    countryCode: 'se',
    spotify: '2Xe8wGZBHG35YT97HRuZBX',
    published: true,
    year: '2024',
    links: {
      facebook: 'https://www.facebook.com/nosleepbythemachine',
      bandcamp: 'https://murtirecords.bandcamp.com',
    },
  },
  {
    id: 'arvsynd',
    name: 'Arvsynd',
    image: images.arvsynd,
    headliner: false,
    description: [
      "With over ten years in the scene, this booking fits Bodyfest like a glove. Peculiar lyrics in Swedish, a lot of vocal samples and old school EBM in excess. Earlier albums sounded a lot like DAF but the latest ones prove that Arvsynd is on their way of shaping their own sound. Extremely danceable, hard EBM and we are sure they're gonna kick ass when they come to Stockholm.",
    ],
    countryCode: 'se',
    spotify: '3u7fuedyChhjZ4g8JFsphU',
    published: true,
    year: '2024',
    links: {
      facebook: 'https://www.facebook.com/Arvsynd',
      bandcamp: 'https://arvsynd.bandcamp.com',
    },
  },
  {
    id: 'alvar',
    name: 'ALVAR',
    image: images.alvar,
    headliner: false,
    description: [
      "ALVAR will once again gather for battle - a mighty army as numberless as sand along the seashore. It has been several years since the last time but now it's time to re-assemble once again in some shape, for a new ride into their own world of sadness, fear and eternal death. A world and vision of trashing beats, screams of anxiety and newly found scrap metal. The celestial voices of dark angels and heavy electronics are back again and you'd better beware.",
    ],
    countryCode: 'se',
    spotify: '3TC85fhi3e2urOAqMPONkP',
    published: true,
    year: '2024',
    links: {
      facebook: 'https://www.facebook.com/ALVARBAND',
      bandcamp: 'https://alvarmusic.bandcamp.com',
    },
  },

  {
    id: '',
    name: '',
    image: null,
    headliner: false,
    description: [''],
    countryCode: 'se',
    spotify: null,
    published: false,
    year: '2023',
  },
];
