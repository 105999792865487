import React from 'react';

const FacebookWidget = () => (
  <section style={{ margin: '30px auto', textAlign: 'center' }}>
    <div
      className="fb-page"
      data-adapt-container-width="true"
      data-height="600"
      data-hide-cover="false"
      data-href="https://www.facebook.com/bodyfest/"
      data-show-facepile="true"
      data-small-header="false"
      data-tabs="timeline"
      data-width="500"
    >
      <blockquote cite="https://www.facebook.com/bodyfest/" className="fb-xfbml-parse-ignore">
        <a href="https://www.facebook.com/bodyfest/">Bodyfest</a>
      </blockquote>
    </div>
  </section>
);

export default FacebookWidget;
