import React from 'react';
import styles from './TicketIcon.module.scss';

const TicketIcon = () => (
  <svg
    className={styles.ticketIcon}
    enableBackground="new 0 0 66 66"
    viewBox="0 0 66 82.5"
  >
    <g>
      <g>
        <path
          d="M64,41.4c0.5,0,1-0.4,1-1v-6.3c0-1.2-1-2.2-2.2-2.2H15.4c-1.2,0-2.2,1-2.2,2.2v6.3c0,0.5,0.5,1,1,1c1.7,0,3,1.4,3,3.1    c0,1.7-1.4,3-3,3c-0.5,0-1,0.5-1,1v6.3c0,1.2,1,2.2,2.2,2.2h47.4c1.2,0,2.2-1,2.2-2.2v-6.3c0-0.5-0.5-1-1-1c-1.7,0-3-1.4-3-3    C60.9,42.8,62.3,41.4,64,41.4z M47.3,52.6H23.4c-0.6,0-1-0.4-1-1s0.4-1,1-1h23.9c0.6,0,1,0.4,1,1S47.8,52.6,47.3,52.6z M47.3,47.8    H23.4c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1h23.9c0.6,0,1,0.4,1,1C48.3,47.4,47.8,47.8,47.3,47.8z M47.3,43.1H23.4c-0.6,0-1-0.4-1-1    s0.4-1,1-1h23.9c0.6,0,1,0.4,1,1S47.8,43.1,47.3,43.1z M47.3,38.4H23.4c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1h23.9c0.6,0,1,0.4,1,1    C48.3,38,47.8,38.4,47.3,38.4z M53.9,55c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1c0-0.6,0.4-1,1-1s1,0.4,1,1V55z M53.9,51c0,0.6-0.4,1-1,1    s-1-0.4-1-1v-1c0-0.6,0.4-1,1-1s1,0.4,1,1V51z M53.9,47c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1c0-0.6,0.4-1,1-1s1,0.4,1,1V47z M53.9,43    c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1c0-0.6,0.4-1,1-1s1,0.4,1,1V43z M53.9,39c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1c0-0.6,0.4-1,1-1s1,0.4,1,1    V39z M53.9,35c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1c0-0.6,0.4-1,1-1s1,0.4,1,1V35z"
          fill="#ffffff"
        />
      </g>
      <g>
        <path
          d="M15.3,44.5c0-0.6-0.5-1.1-1-1.1c-1.6,0-3-1.4-3-3v-6.3c0-0.5,0.1-1,0.3-1.4c-0.3-0.1-0.5-0.2-0.6-0.5    c-0.3-0.5,0-1.1,0.4-1.3l21.3-10.7c0.5-0.3,1.1,0,1.3,0.4c0.3,0.5,0.1,1.1-0.4,1.3l-16,8h6l11.1-5.6c0.5-0.3,1.1-0.1,1.3,0.4    c0.3,0.5,0,1.1-0.4,1.3L28.1,30h14.7c0,0-0.1-0.1-0.1-0.1L42.2,29c-0.3-0.5,0-1.1,0.4-1.3c0.5-0.3,1.1,0,1.4,0.4l0.4,0.9    c0.2,0.4,0.1,0.7-0.1,1h12.6c0.1-0.4,0-0.9-0.2-1.3l-2.8-5.6c-0.3-0.5-0.8-0.7-1.3-0.4c-1.5,0.8-3.4,0.1-4.1-1.4    c-0.8-1.5-0.1-3.3,1.4-4.1c0.2-0.1,0.4-0.3,0.5-0.6c0.1-0.3,0.1-0.5-0.1-0.8l-2.8-5.6c-0.3-0.5-0.7-0.9-1.3-1.1    c-0.5-0.2-1.1-0.1-1.7,0.1l-8.9,4.5c0.5-0.2,1.1,0,1.4,0.4l0.3,0.5c0.2,0.5,0,1.1-0.5,1.3c-0.5,0.3-1.1,0-1.3-0.4L35.2,15    c-0.3-0.5,0-1.1,0.4-1.3L2.2,30.5c-1.1,0.5-1.5,1.9-1,2.9L4,39c0.2,0.5,0.8,0.7,1.3,0.5c1.5-0.7,3.3-0.1,4.1,1.4    c0.7,1.5,0.1,3.3-1.3,4.1c-0.5,0.3-0.7,0.9-0.4,1.3l2.8,5.6c0.2,0.4,0.4,0.6,0.8,0.8v-4.2c0-1.6,1.3-3,3-3    C14.8,45.5,15.3,45.1,15.3,44.5z M42.2,24.5l0.5,0.9c0.3,0.5,0,1.1-0.4,1.3c-0.5,0.3-1.1,0.1-1.4-0.4l-0.4-0.9    c-0.3-0.5-0.1-1.1,0.4-1.3C41.4,23.8,42,24,42.2,24.5z M40.4,20.9l0.4,0.9c0.6,1.2-1.2,2.1-1.8,0.9l-0.5-0.9    c-0.3-0.5,0-1.1,0.4-1.3C39.6,20.2,40.2,20.4,40.4,20.9z M37.3,16.9c0.5-0.2,1.1,0,1.3,0.5l0.5,0.9c0.3,0.5,0,1.1-0.4,1.3    c-0.5,0.3-1.1,0-1.3-0.4l-0.4-0.9C36.6,17.8,36.8,17.2,37.3,16.9z"
          fill="#ffffff"
        />
      </g>
    </g>
  </svg>
);

export default TicketIcon;
