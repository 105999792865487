import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './SpotifyPlayer.module.scss';

class SpotifyPlayer extends PureComponent {
  state = {
    loading: true,
  };

  spotifyPlayerRef = React.createRef();

  componentDidMount() {
    setTimeout(() => {
      this.spotifyPlayerRef.current.className = `${this.spotifyPlayerRef.current.className} ${styles.fadeIn}`;
      this.setState({ loading: false });
    }, 1000);
  }
  render() {
    const { spotifyId } = this.props;

    return (
      <>
        {this.state.loading && (
          <p className={styles.loader}>loading Spotify player...</p>
        )}
        <div ref={this.spotifyPlayerRef} className={styles.wrapper}>
          <iframe
            frameBorder="0"
            height="80"
            src={`https://embed.spotify.com/?uri=spotify:artist:${spotifyId}`}
            title="Spotify Player"
            width="300"
          />
        </div>
      </>
    );
  }
}

SpotifyPlayer.propTypes = { spotifyId: PropTypes.string.isRequired };

export default SpotifyPlayer;
