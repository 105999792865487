import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './SoundCloudPlayer.module.scss';

class SoundCloudPlayer extends PureComponent {
  state = {
    loading: true,
  };

  soundCloudPlayerRef = React.createRef();

  componentDidMount() {
    setTimeout(() => {
      this.soundCloudPlayerRef.current.className = `${this.soundCloudPlayerRef.current.className} ${styles.fadeIn}`;
      this.setState({ loading: false });
    }, 1000);
  }
  render() {
    const { soundCloudId } = this.props;

    return (
      <>
        {this.state.loading && (
          <p className={styles.loader}>loading SoundCloud player...</p>
        )}
        <div ref={this.soundCloudPlayerRef} className={styles.wrapper}>
          <iframe
            allow="autoplay"
            frameBorder="no"
            height="166"
            scrolling="no"
            src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${soundCloudId}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
            title="SoundCloud Player"
            width="320"
          />
        </div>
      </>
    );
  }
}

SoundCloudPlayer.propTypes = { soundCloudId: PropTypes.string.isRequired };

export default SoundCloudPlayer;
