import React, { useState } from 'react';
import { artistHistory } from '../../data';
import {
  flyer2010,
  flyer2011,
  flyer2012,
  flyer2013,
  flyer2014,
  flyer2015,
  flyer2016,
  flyer2017,
  flyer2019,
  flyer2021,
  flyer2022,
  flyer2023,
} from '../../data/images';
import styles from './ArtistHistory.module.scss';

const IMAGES = {
  all: null,
  2010: flyer2010,
  2011: flyer2011,
  2012: flyer2012,
  2013: flyer2013,
  2014: flyer2014,
  2015: flyer2015,
  2016: flyer2016,
  2017: flyer2017,
  2018: null,
  2019: flyer2019,
  2021: flyer2021,
  2022: flyer2022,
  2023: flyer2023,
};

const ArtistHistory = () => {
  const [selectedYear, setSelectedYear] = useState('all');
  const [clickedArtist, setClickedArtist] = useState(null);

  const getYears = () => {
    let yearArray = [];

    artistHistory.reduce((acc, artist) => {
      const artistYears = artist?.years;
      artistYears.forEach((year) => {
        yearArray[year] = year;
      });

      return acc;
    });

    return Object.assign(yearArray);
  };

  const handleYearClick = (event) => {
    setClickedArtist(null);
    setSelectedYear(event.currentTarget.value);
  };

  const handleArtistClick = (artist) => () => {
    setClickedArtist(artist);
    setSelectedYear(null);
  };

  const artistIsActive = (artist) => {
    const { years, name } = artist;

    return selectedYear === 'all' || years.includes(selectedYear) || clickedArtist?.name === name;
  };

  const years = getYears();

  const flyer = IMAGES[selectedYear];
  const imageContainerWidth = flyer ? 320 : 0;

  return (
    <div className={styles.mainWrapper}>
      <section
        className={styles.section}
        style={{
          justifyContent: flyer ? 'space-around' : 'center',
        }}
      >
        <div className={styles.yearsWrapper}>
          <h2 className={styles.artistHistoryHeading}>During the years the following bands have performed:</h2>
          <div className={styles.yearMenu}>
            <button
              className={`${styles.year} ${selectedYear === 'all' ? styles.selectedYear : ''}`}
              value="all"
              onClick={handleYearClick}
            >
              all years
            </button>
            {years.map((year) => (
              <button
                key={year}
                className={`${styles.year} ${
                  selectedYear === year || clickedArtist?.years.includes(year) ? styles.selectedYear : ''
                }`}
                value={year}
                onClick={handleYearClick}
              >
                {year}
              </button>
            ))}
          </div>
          <div className={styles.artistHistory}>
            {artistHistory.map((artist) => (
              <span
                key={artist.name}
                className={`${styles.artist} ${artistIsActive(artist) ? styles.activeArtist : ''}`}
                onClick={handleArtistClick(artist)}
              >
                {artist.name}
              </span>
            ))}
          </div>
        </div>
        <div
          className={styles.flyerWrapper}
          style={{
            width: imageContainerWidth,
          }}
        >
          {flyer && <img alt={`Bodyfest flyer ${selectedYear}`} src={flyer} style={{ width: '100%' }} />}
        </div>
      </section>
    </div>
  );
};

export default ArtistHistory;
