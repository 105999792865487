import React from 'react';
import PropTypes from 'prop-types';
import styles from './TicketLink.module.scss';
import TicketIcon from '../TicketIcon/TicketIcon';

const TicketLink = ({ ticketLink, ticketWrapperRef }) => (
  <>
    <div ref={ticketWrapperRef} className={styles.ticketWrapper}>
      <div className={styles.ticketLink}>
        <a className={styles.linkText} href={ticketLink} rel="noopener noreferrer" target="_blank">
          <TicketIcon />
          Get your tickets here!
        </a>
      </div>
    </div>
  </>
);

TicketLink.propTypes = {
  ticketLink: PropTypes.string.isRequired,
  ticketWrapperRef: PropTypes.object.isRequired, // hmmmm?
};

export default TicketLink;
