import React from 'react';
import { getCurrentSchedule } from '../../utils';
import styles from './Schedule.module.scss';

const Schedule = () => {
  const schedule = getCurrentSchedule();

  if (!schedule) {
    return null;
  }

  return (
    <>
      <ul className={styles.list}>
        <li className={styles.item}>
          <h2 className={styles.heading}>SCHEDULE</h2>
        </li>

        {schedule.map(scheduleEvent => {
          const { event, time, stage } = scheduleEvent;

          return (
            <li key={event} className={styles.item}>
              <span className={styles.time}>{time}</span>
              <span className={styles.event}>{event}</span>
              <span className={styles.stage}>{stage}</span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Schedule;
